import { ReduxProvider } from "components/common/ReduxProvider/ReduxProvider";
import { ImageUploader } from "components/common/Uploader/ImageUploader/ImageUploader";
import { useResizeUploader } from "./useResizeUploader";
import { getHtmlFileInputId, getSupportedExtensions } from "./utils";
import { useRouter } from "next/router";

const ResizeUploaderContent = () => {
    const { deleteImage, deleteAllImages, filterFiles } = useResizeUploader();
    const { pathname } = useRouter();
    return (
        <ImageUploader
            deleteImage={deleteImage}
            deleteAllImages={deleteAllImages}
            maxUploads={1}
            analytics={{
                pageKey: "image_resizer",
                pageTitle: "Image Resizer",
            }}
            htmlFileInputId={getHtmlFileInputId(pathname)}
            skipImportAfterTaskCreate={true}
            filterFiles={filterFiles}
            supportedExtensions={getSupportedExtensions(pathname)}
        />
    );
};

export const ResizeUploader = () => {
    return (
        <ReduxProvider>
            <ResizeUploaderContent />
        </ReduxProvider>
    );
};
